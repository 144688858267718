/* eslint-disable */
import girl1 from '../assets/svg/testimonials/girl1.svg'
import girl2 from '../assets/svg/testimonials/girl2.svg'
import girl3 from '../assets/svg/testimonials/girl3.svg'
import boy1 from '../assets/svg/testimonials/boy1.svg'
import boy2 from '../assets/svg/testimonials/boy2.svg'
import boy3 from '../assets/svg/testimonials/boy3.svg'



export const testimonialsData = [
    {
        id: 1,
        name: 'Parveen A.',
        title: 'Former Supervisor',
        text: "Andrew is a sel-motivated and dedicated individual. .",
        image: boy2
    },
    {
        id: 2,
        name: 'Nicholars I.',
        title: 'Former research Supervisor',
        text: "In research cases, He is diverse with interest in exploring new ideas.",
        image: girl2
    },
    {
        id: 3,
        name: 'Phoebe Buffay',
        title: 'Graduate Associate',
        text: "Heard of the song detectable!!' ? This gentle man is a fast thinker.)",
        image: girl1
    },
    {
        id: 4,
        name: 'Joey T.',
        title: 'Research Associate',
        text: "At UBOS, he was awell behaved man. Andrew.!",
        image: boy1
    },
    {
        id: 5,
        name: 'Rachel Green',
        title: 'Sales Executive',
        text: 'Andrew was coopereative and interactive during the time we worked together.',
        image: girl3
    },
    {
        id: 6,
        name: 'K Gracious',
        title: 'Friend',
        text: 'Never met a better friend as him.',
        image: girl2
    }
]

